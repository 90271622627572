// src/apiService.js
import axios from 'axios';

const API_KEY = "sk-proj-mmTGMQhnUnGLJuNYK14rT3BlbkFJuoFHxlqKFMWM4Fe4rnw1"; // Replace with your actual API key
const API_URL = 'https://api.openai.com/v1/chat/completions';

export const fetchChatResponse = async (messages) => {
  try {
  const response = await axios.post(
    API_URL,
    {
      model: 'gpt-4',  // Adjust the model if needed
      messages: messages,
      // assistant_id: 'asst_BHrfmn0hpAFQF0nE0ShyrgOu',  // Include the assistant ID here
    },
    {
      headers: {
        'Authorization': `Bearer ${API_KEY}`,
        'Content-Type': 'application/json',
      },
    }
  );
  return response.data;
 } catch (error) {
    console.error("Error fetching chat response:", error.response ? error.response.data : error.message);
    throw error;
  }
};
